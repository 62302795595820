<template>
  <Head>
    <title>{{$t('message.seo.startup.title')}}</title>
    <meta name="description" :content="$t('message.seo.startup.description')"/>
    <meta name="keywords" :content="$t('message.seo.startup.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="md:col-start-3 md:col-end-9 mt-16">
      <div class="block">
        <!--<div class="hidden md:block float-right md:col-start-6 md:col-end-9 w-1/3 ml-8 lg:ml-40 mb-16">
          <img class="w-full mt-16" src="../assets/img/dueren_interior.jpg" :alt="$t('message.AR.stadtplanung.imgAlt')">
        </div>-->
        <h1 class="text-red font-sen font-bold text-5xl mb-12 mr-8 max-w-prose">{{$t('message.Startup.title')}}</h1>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.Startup.subtitle')}}</h2>
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.Startup.t1')}}</p>
        <!--<h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.AR.stadtplanung.h2')}}</h2>
        <p class="font-source text-lg text-black max-w-prose mb-20">{{$t('message.AR.stadtplanung.p2')}}</p>
        <img class="block md:hidden w-full mt-16 p-16" src="../assets/img/dueren_interior.jpg" :alt="$t('message.AR.stadtplanung.imgAlt')">-->
      </div>
    </div>
    <div class="md:col-start-3 md:col-end-8 mb-16">
        <h2 class="font-sen text-red font-bold text-3xl mb-8" v-html="$t('message.Team.teamTitle')"></h2>
        <div class="grid grid-cols-3 gap-6 md:gap-12">
          <teamCard v-for="(member, name) in team" :key="name" :member="member" :name="name"></teamCard>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'
import TeamCard from '@/components/TeamCard.vue'
import { team } from '../team'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'

export default defineComponent({
  name: 'Startup',
  components: {
    Head,
    TeamCard,
    SideBreadcrumbs
  },
  data () {
    const jsonld = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://cityscaper.de',
      logo: 'https://cityscaper.de/logo.png'
    }
    return {
      jsonld,
      team
    }
  },
  mounted () {
    const jld = document.createElement('script')
    jld.setAttribute('type', 'application/ld+json')
    jld.text = JSON.stringify(this.jsonld)
    document.head.appendChild(jld)
  }
})
</script>

<style scoped>

</style>
