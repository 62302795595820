<template>
  <Head>
    <title>{{$t('message.seo.stadtplanung.title')}}</title>
    <meta name="description" :content="$t('message.seo.stadtplanung.description')"/>
    <meta name="keywords" :content="$t('message.seo.stadtplanung.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="md:col-start-3 md:col-end-9 mt-16">
      <div class="block">
        <div class="hidden md:block float-right md:col-start-6 md:col-end-9 w-5/12 ml-4 lg:ml-16 xl:ml-20 mb-16">
          <img class="w-full mt-16" src="../assets/img/stadtpl_mockup.jpg" :alt="$t('message.AR.stadtplanung.imgAlt')">
        </div>
        <h1 class="text-red font-sen font-bold text-5xl mb-12 mr-8 max-w-prose">{{$t('message.AR.stadtplanung.title')}}</h1>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.AR.stadtplanung.subtitle')}}</h2>
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.AR.stadtplanung.text')}}</p>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.AR.stadtplanung.h2')}}</h2>
        <p class="font-source text-lg text-black max-w-prose mb-20">{{$t('message.AR.stadtplanung.p2')}}</p>
        <img class="block md:hidden w-full mt-16 p-8" src="../assets/img/stadtpl_mockup.jpg" :alt="$t('message.AR.stadtplanung.imgAlt')">
      </div>
    </div>
  </div>
  <!-- Vorteile -->
  <div class="grid grid-cols-8 bg-gray-200 mt-20">
    <div class="col-start-2 lg:col-start-3 col-end-8 mt-32 mb-32">
      <h2 class="text-black font-sen font-bold text-4xl mb-10">{{$t('message.AR.benefits')}}</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 auto-rows-fr grid-flow-row-dense">
        <!-- Vorteil cards -- thumbnails 16:9 -->
        <benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'zusammenarbeit.svg'" :text="$t('message.AR.stadtplanung.b1')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'moneyshot.jpg'" :alt="$t('message.AR.stadtplanung.altT1')" :text="$t('message.AR.stadtplanung.t1')" :textBottom="true"/>
        <benefit-card class="col-span-1 md:col-start-2 row-span-1" :is-text-card="true" :icon="'visualisieren-2.svg'" :text="$t('message.AR.stadtplanung.b2')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'move_bench.mp4'" :alt="$t('message.AR.stadtplanung.altT2')" :text="$t('message.AR.stadtplanung.t2')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'gestalten.svg'" :text="$t('message.AR.stadtplanung.b4')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'cliphoehe3xs.mp4'" :alt="$t('message.AR.stadtplanung.altT3')" :text="$t('message.AR.stadtplanung.t3')" :textBottom="false"/>
        <benefit-card class="col-span-1 md:col-start-2 row-span-1" :is-text-card="true" :icon="'vergleichen_haus.svg'" :text="$t('message.AR.stadtplanung.b3')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'VaraintShiftB1.mp4'" :alt="$t('message.AR.stadtplanung.altT4')" :text="$t('message.AR.stadtplanung.t4')" :textBottom="false"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'visualisieren.svg'" :text="$t('message.AR.stadtplanung.b5')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'microsite_w.jpg'" :alt="$t('message.AR.stadtplanung.altT5')" :text="$t('message.AR.stadtplanung.t5')" :textBottom="false"/>
        <benefit-card class="col-span-1 md:col-start-2 row-span-1" :is-text-card="true" :icon="'dokumentieren.svg'" :text="$t('message.AR.stadtplanung.b6')" :textBottom="false"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'analyse.jpg'" :alt="$t('message.AR.stadtplanung.altT6')" :text="$t('message.AR.stadtplanung.t6')" :textBottom="false"/>
      </div>
    </div>
  </div>
  <!-- Fazit -->
  <div class="md:grid md:grid-cols-8 md:pl-0 md:pr-0">
    <div class="md:col-start-3 md:col-end-8 mt-32 mb-32 pl-10 pr-10 md:pl-0 md:pr-0">
      <h2 class="text-black font-sen font-bold text-5xl mb-12" v-html="$t('message.AR.conclusion')"></h2>
      <p class="text-black font-source font-semibold text-2xl" v-html="$t('message.AR.stadtplanung.conclusion')"></p>
    </div>
    <get-in-touch/>
  </div>
  <!-- Links zu den anderen Bereichen -->
  <other-customers />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import BenefitCard from '@/components/BenefitCard.vue'
import GetInTouch from '@/components/GetInTouch.vue'
import OtherCustomers from '@/components/OtherCustomers.vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: 'Stadtplanung',
  components: {
    OtherCustomers,
    SideBreadcrumbs,
    BenefitCard,
    GetInTouch,
    Head
  }
})
</script>

<style scoped>

</style>
