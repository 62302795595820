
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'
import TeamCard from '@/components/TeamCard.vue'
import { team } from '../team'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'

export default defineComponent({
  name: 'Startup',
  components: {
    Head,
    TeamCard,
    SideBreadcrumbs
  },
  data () {
    const jsonld = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://cityscaper.de',
      logo: 'https://cityscaper.de/logo.png'
    }
    return {
      jsonld,
      team
    }
  },
  mounted () {
    const jld = document.createElement('script')
    jld.setAttribute('type', 'application/ld+json')
    jld.text = JSON.stringify(this.jsonld)
    document.head.appendChild(jld)
  }
})
