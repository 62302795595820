export const articles: { [id: string]: any } = {
  BMWi: {
    tag: 'Wettbewerb',
    thumbnail: 'BMWi_header.jpg',
    splash: 'BMWi_header.jpg',
    img1: 'PlacesLadies.jpg',
    img2: 'PlacesLads.jpg',
    img3: 'Huckarde.jpg',
    img4: 'Interface_2.jpeg',
    img5: 'PlacesAnja.jpg'
  }
}

export const articleTexts = {
  messages: {
    de: {
      BMWi: {
        title: 'Gründungswettbewerb “Digitale Innovationen"',
        genre: 'Auszeichnung',
        subtitle: 'Aachener Startup cityscaper gewinnt den Sonderpreis “Digitale Städte und Innovationen” des BMWi',
        location: 'Berlin',
        thumbAlt: 'Interaktives Straßenplanungstool von cityscaper',
        splashAlt: 'Interaktives Straßenplanungstool von cityscaper',
        time: '24.11.2021',
        partner: '<a href="https://www.bmwi.de/Navigation/DE/Home/home.html" target="_blank" rel="noopener noreferrer">BMWi</a>',
        t1: 'Das Aachener Startup cityscaper wurde am 24.11.2021 beim diesjährigen bundesweiten Gründungswettbewerb “Digitale Innovationen” des BMWi mit dem Sonderpreis “Digitale Städte und Innovationen” ausgezeichnet. Das Unternehmen überzeugte mit seinen digitalen Konzepten zur Stärkung der Bürgerbeteiligung in der Stadtplanung.',
        subsubtitle: 'Sonderpreis der Initiative “StadtLandDigital” des BMWi',
        t2: 'Das BMWi zeichnet im Rahmen des Wettbewerbs Gründungsideen für digitale Ökosysteme aus, die das Leben und Arbeiten der Menschen bereichern, sowie eine innovative und effiziente Vernetzung des Wohlstands und der Lebensqualität in einer Region, einer Stadt oder eine Kommune steigern.<br><br>' +
        'Städte stehen bei umfangreichen Planungs- und Umgestaltungsprozessen vor großen Herausforderungen. Eine erfolgreiche Umsetzung gelingt einfacher, wenn alle Beteiligten wie Bürgerinnen und Bürger, Gewerbe, Industrie, Verwaltung und Politik umfassend zu wichtigen Plänen informiert werden.<br><br>' +
        'cityscaper bietet eine digitale Kommunikationsplattform für die Stadtplanung, die einen interaktiven Meinungsaustausch in den entscheidenden Phasen der Planungsprozesse möglich macht. cityscaper stellt virtuelle Projektentwürfe leicht verständlich in 3D dar, um die Auswirkungen der unterschiedlichen Planungsoptionen anschaulich zu vermitteln. Dies gelingt sowohl zuhause im Webbrowser als auch mit einer App für mobile Endgeräte. In der cityscaper-App erfasst die Kamera des Mobilgeräts das Umfeld des Planungsvorhabens. Die Nutzenden können neue Objekte aus einem Katalog mithilfe von Augmented-Reality-Anwendungen in die Umgebung einfügen und so gemeinsam das zukünftige Stadtbild digital entstehen lassen. Verschiedene Entwürfe werden auf der Plattform anschaulich gegenübergestellt und Anregungen von Beteiligten können in 3D skizziert und dokumentiert werden.<br><br>' +
        'Gründer Robin Römer erklärte: “Startups scheitern oft am Timing. Die Auszeichnung bestätigt, dass Bund, Länder und Städte und Kommunen gerade mit dem Umdenken beginnen und Bürgerbeteiligung nicht mehr nur als Pflichtaufgabe, sondern als essentiellen Mehrwert für Stadtplanungsprozesse begreifen. Damit nicht nur die großen Städte und Kommunen von neuen, innovativen Lösungen profitieren, ist die private Wirtschaft gefragt. Die Zusammenarbeit zwischen jungen Unternehmen als Innovationstreiber und Stadtverwaltungen als Prozessexperten ist eine gute Voraussetzung, um in der Breite neue Lösungen in Deutschland allen Bürger:innen zugänglich zu machen. Der Wettbewerb ist dabei eine große Unterstützung, solchen Kooperation die nötige Bühne zu geben.”<br><br><i>Titelbild: Copyright cityscaper GmbH, Bild oben: Copyright Places _ VR Festival / Ole-Kristian Heyer</i>',
        imgDescription1: 'Bürgerinnen bei der Verwendung der App',
        imgAlt1: 'Bürgerinnen, cityscaper, Augmented Reality, Stadtplanung, grün',
        imgDescription2: 'Bürger:innen bei der Verwendung der App, Copyright Places _ VR Festival / Ole-Kristian Heyer',
        imgAlt2: 'Bürgerinnen, cityscaper, Augmented Reality, Stadtplanung, grün',
        imgDescription3: 'Möbilierung eines Parks',
        imgAlt3: 'Co-Kreation, Möbel, Gestalten, cityscaper, Augmented Reality',
        imgDescription4: 'Interface der App',
        imgAlt4: 'Interface, cityscaper, Augmented Reality, Stadtplanung',
        imgDescription5: 'Grüne Utopie einer Straße',
        imgAlt5: 'Co-Kreation, grün, Lala.Ruhr, VRPlaces Festival, Augmented Reality',
        seo: {
          title: 'Gründungswettbewerb “Digitale Innovationen"',
          description: 'Aachener Startup cityscaper gewinnt den Sonderpreis “Digitale Städte und Innovationen” des BMWig',
          keywords: 'BMWi, Verkehrsplanung, Startup'
        }
      }
    }
  }
}
