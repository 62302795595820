<template>
<div class="">
  <div v-if="isTextCard" class="flex flex-wrap content-center bg-white p-8 2xl:p-16 w-full h-full">
    <img class="w-12 h-12 lg:w-14 lg:h-14" :src="require('@/assets/ui/icon/' + icon)" :alt="'Icon ' + icon"/>
    <p class="w-full max-h-16 overflow-visible font-source text-black text-sm lg:text-lg" v-html="$t(text)"></p>
  </div>
  <div v-if="!isTextCard" class="w-full h-full relative">
    <img v-if="!thumbnail.endsWith('.mp4')" class="absolute object-cover w-full h-full" :src="require('@/assets/img/benefits/' + thumbnail)" :alt="alt" />
    <video v-if="thumbnail.endsWith('.mp4')" autoplay loop muted class="absolute object-cover w-full h-full">
      <source :src="require('@/assets/img/benefits/' + thumbnail)" type="video/mp4">
    </video>
    <div class="absolute w-full h-full bg-gradient-to-b from-black to-clear opacity-40"></div>
    <div :class="{ 'bottom-0': textBottom }" class="absolute text-white font-sen p-8 uppercase" v-html="$t(text)"></div>
    <img class="object-cover w-full invisible" src="../assets/img/benefits/placeholder.jpg" />
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BenefitCard',
  props: ['isTextCard', 'icon', 'text', 'thumbnail', 'textBottom', 'alt']
})
</script>

<style scoped>

</style>
