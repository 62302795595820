
import { defineComponent } from 'vue'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import BenefitCard from '@/components/BenefitCard.vue'
import GetInTouch from '@/components/GetInTouch.vue'
import OtherCustomers from '@/components/OtherCustomers.vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: 'Stadtplanung',
  components: {
    OtherCustomers,
    SideBreadcrumbs,
    BenefitCard,
    GetInTouch,
    Head
  }
})
