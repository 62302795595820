<template>
  <div class="col-start-2 col-end-7 flex flex-wrap content-end">
    <h2 class="font-sen text-2xl font-bold">{{$t('message.presse.learnMore')}}</h2>
  </div>
  <div  v-bind:class="{'border-black': canScrollRight, 'border-gray-200': !canScrollRight, 'hover:shadow-xl': canScrollRight }"
        class="hidden md:inline-block ml-auto col-start-7 col-end-8 w-16 h-16 border-2 cursor-pointer">
    <div v-bind:class="{ 'bg-black': canScrollRight, 'bg-gray-200': !canScrollRight }" class="arrow-icon w-full h-full"></div>
  </div>
  <div v-bind:class="{ 'col-end-9 space-x-12' : canScrollRight, 'col-end-8 space-x-24' : !canScrollRight }" class="hidden relative col-start-2 md:flex flex-nowrap  mt-16">
    <div class="relative w-1/4 flex-shrink-0 flex-grow max-w-sm" v-for="(project, id) in articleDict" v-bind:key="id">
      <PressThumb :project="project" :id="id" />
    </div>
    <div v-bind:class="{'hidden': !canScrollRight}" class="absolute h-full w-60 right-0 bg-gradient-to-l from-white opacity-70"></div>
  </div>
  <div v-if="firstProject" class="col-start-2 col-end-8 md:hidden w-full p-8">
    <PressThumb :project="firstProject?.project" :id="firstProject?.id" />
  </div>
  <div class="col-span-8 h-32"></div>
</template>

<script type="ts">
import { articles } from '../references/PressStore'
import PressThumb from './PressThumb'

export default {
  name: 'PressCarousell',
  components: {
    PressThumb
  },
  data () {
    return {
      articleDict: articles,
      articleIDs: Object.keys(articles),
      canScrollRight: Object.keys(articles).length > 3
    }
  },
  computed: {
    firstProject () {
      if (this.articleIDs && this.articleIDs.length > 0) {
        return { project: articles[this.articleIDs[0]], id: this.articleIDs[0] }
      } else {
        return undefined
      }
    }
  }
}
</script>

<style scoped>

</style>
