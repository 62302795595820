
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'

export default defineComponent({
  name: 'Try',
  components: {
    Head,
    SideBreadcrumbs
  }
})
