<template>
  <Head>
    <title>{{$t('message.seo.ar.title')}}</title>
    <meta name="description" :content="$t('message.seo.ar.description')"/>
    <meta name="keywords" :content="$t('message.seo.ar.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="md:col-start-2 md:col-end-8">
      <div class="md:grid md:grid-cols-5 mb-16">
        <div class="md:col-start-1 lg:col-start-2 md:col-end-6 mt-16 mb-8">
          <h1 class="float-left text-red font-sen font-bold text-5xl mb-8">{{$t('message.AR.title')}}</h1>
          <img class="hidden md:block float-right md:mt-16 w-2/3 lg:mt-0 xl:mt-16 2xl:mt-0"
               src="../assets/img/ar_illustration.svg" :alt="$t('message.alt.arIllustration')"/>
          <h2 class="clear-left text-black font-sen font-bold text-2xl whitespace-nowrap" v-html="$t('message.claim2')"></h2>
          <img :alt="$t('message.alt.arIllustration')" class="md:hidden block ml-auto mr-auto md:mr-0 md:ml-0 md:float-right md:mt-16 lg:mt-0 xl:mt-16 2xl:mt-0" src="../assets/img/ar_illustration.svg" />
        </div>
        <div class="md:col-start-1 lg:col-start-2 col-end-5 mb-32 flex flex-col">
          <h2 class="text-black text-xl font-sen mb-8 font-bold" v-html="$t('message.AR.h2')"></h2>
          <p class="font-source text-lg text-black" v-html="$t('message.AR.p2')"></p>
        </div>
        <div class="md:col-span-5 flex flex-col md:grid md:grid-cols-2">
          <div class="lg:col-span-1 mb-8">
            <img src="../assets/img/ar_method.svg" :alt="$t('message.alt.arMethod')"/>
          </div>
          <div class="lg:col-span-1 flex flex-wrap content-center">
            <h2 class="font-sen text-black text-xl mb-8 font-bold" v-html="$t('message.AR.h3')"></h2>
            <p class="font-source text-lg text-black" v-html="$t('message.AR.p3')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Section Customer Segments -->
  <div class="grid grid-cols-8 gap-0 bg-gray-200 pt-32 pb-32">
    <div class="col-start-2 col-end-8">
      <h2 class="font-sen font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl pb-16">{{ $t("message.AR.learnMore") }}</h2>
      <!--<div class="md:grid md:grid-cols-2 md:gap-4 space-y-4 md:space-y-0">
        <CustomerSegmentCard :text="'message.customer.stadt'" :icon="'icon_stadtplanung.svg'" :alt="$t('message.alt.iconStadtplanung')"/>
        <CustomerSegmentCard :text="'message.customer.architektur'" :icon="'icon_architektur.svg'" :alt="$t('message.alt.iconArchitektur')"/>
        <CustomerSegmentCard :text="'message.customer.immo'" :icon="'icon_immo.svg'" :alt="$t('message.alt.iconImmo')"/>
        <CustomerSegmentCard :text="'message.customer.infra'" :icon="'icon_infrastrukt.svg'" :alt="$t('message.alt.iconInfrastruktur')"/>
      </div>-->
      <div class="flex flex-col md:grid md:grid-cols-2 md:gap-8 space-y-8 md:space-y-0">
        <router-link v-for="(customer, key) in customers" :key="key" :to="customer.path"><CustomerSegmentCard :text="customer.message" :icon="customer.icon"/></router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import CustomerSegmentCard from '@/components/CustomerSegmentCard.vue'
import { Head } from '@egoist/vue-head'
import { customers } from '../Customers'

@Options({
  components: {
    SideBreadcrumbs,
    CustomerSegmentCard,
    Head
  },
  data () {
    return {
      customers: customers
    }
  }
})
export default class AR extends Vue {}
</script>
