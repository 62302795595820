
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: 'DownloadPage',
  mounted () {
    const typeOfDevice = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      Mac: function () {
        return navigator.userAgent.match(/Mac/i)
      },
      any: function () {
        return (typeOfDevice.Android() || typeOfDevice.BlackBerry() || typeOfDevice.iOS() || typeOfDevice.Opera() || typeOfDevice.Windows() || typeOfDevice.Mac())
      }
    }
    if (typeOfDevice.Android()) {
      document.location.href = 'https://play.google.com/store/apps/details?id=com.witt.cityscaper&gl=DE'
    } else if (typeOfDevice.iOS()) {
      document.location.href = 'https://apps.apple.com/de/app/cityscaper/id1576131878'
    }
  },
  data () {
    return {
    }
  },
  components: {
    Head
  }
})
