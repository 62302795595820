
import { Options, Vue } from 'vue-class-component'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import CustomerSegmentCard from '@/components/CustomerSegmentCard.vue'
import { Head } from '@egoist/vue-head'
import { customers } from '../Customers'

@Options({
  components: {
    SideBreadcrumbs,
    CustomerSegmentCard,
    Head
  },
  data () {
    return {
      customers: customers
    }
  }
})
export default class AR extends Vue {}
