<template>
  <div class="w-full flex flex-col">
    <div class="w-full relative mb-2">
      <div class="absolute z-10 w-full h-full transition duration-300 opacity-0 hover:opacity-100">
        <div class="absolute text-white w-full h-full z-20 flex items-center p-4 text-xs xl:text-md"><p class="m-auto font-source" v-html="$t('message.Team.' + name + '.catchline')"></p></div>
        <div class="absolute w-full h-full bg-black opacity-30 z-10"></div>
      </div>
      <img class="w-full" :src="require('@/assets/img/team/' + member.portrait)" :alt="$t('message.Team.altPortrait') + ' ' + member.fullName"/>
    </div>
    <p class="font-sen font-bold text-lg text-center p-1" v-html="member.fullName"></p>
    <p class="font-source text-xs uppercase text-center p-1" v-html="$t('message.Team.' + name + '.role')"></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TeamCard',
  props: ['member', 'name']
})
</script>

<style scoped>

</style>
