<template>
  <Head>
    <title>{{$t('message.seo.architektur.title')}}</title>
    <meta name="description" :content="$t('message.seo.architektur.description')"/>
    <meta name="keywords" :content="$t('message.seo.architektur.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="md:col-start-3 md:col-end-9 mt-16">
      <div class="block">
        <!--<div class="hidden md:block float-right md:col-start-6 md:col-end-9 w-1/3 ml-8 lg:ml-40 mb-16">
          <img class="w-full mt-16" src="../assets/img/dueren_interior.jpg" :alt="$t('message.AR.architektur.imgAlt')">
        </div>-->
        <video ref="scrollVid" autoplay loop muted class="hidden md:block float-right md:col-start-6 md:col-end-9 w-4/12 ml-8 lg:ml-40 mb-16">
          <source src="../assets/img/daynightshift.mp4" type="video/mp4">
        </video>
        <h1 class="text-red font-sen font-bold text-5xl mb-12 mr-8 max-w-prose">{{$t('message.AR.architektur.title')}}</h1>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.AR.architektur.subtitle')}}</h2>
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.AR.architektur.text')}}</p>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.AR.architektur.h2')}}</h2>
        <p class="font-source text-lg text-black max-w-prose mb-20">{{$t('message.AR.architektur.p2')}}</p>

        <video ref="scrollVid" autoplay loop muted class="block md:hidden w-full mt-16 p-8">
          <source src="../assets/img/daynightshift.mp4" type="video/mp4">
        </video>
        <!--<img class="block md:hidden w-full mt-16 p-16" src="../assets/img/dueren_interior.jpg" :alt="$t('message.AR.architektur.imgAlt')">-->
      </div>
    </div>
  </div>
  <!-- Vorteile -->
  <div class="grid grid-cols-8 bg-gray-200 mt-20">
    <div class="col-start-2 lg:col-start-3 col-end-8 mt-32 mb-32">
      <h2 class="text-black font-sen font-bold text-4xl mb-10">{{$t('message.AR.benefits')}}</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 auto-rows-fr grid-flow-row-dense">
        <!-- Vorteil cards -- thumbnails 16:9 -->
        <!-- change order on Nx2 grid by setting first object to col-start-2 -->
        <benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'perspketive.svg'" :text="$t('message.AR.architektur.b1')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'arch_t1.jpg'" :alt="$t('message.AR.architektur.altT1')" :text="$t('message.AR.architektur.t1')" :textBottom="true"/>
        <benefit-card class="col-span-1 md:col-start-2 row-span-1" :is-text-card="true" :icon="'vergleich-material.svg'" :text="$t('message.AR.architektur.b2')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="false" :thumbnail="'move_bench.mp4'" :alt="$t('message.AR.architektur.altT2')" :text="$t('message.AR.architektur.t2')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'bauherr.svg'" :text="$t('message.AR.architektur.b3')"/>
        <benefit-card class="col-span-1 md:col-start-2 row-span-1" :is-text-card="false" :thumbnail="'microsite_w.jpg'" :alt="$t('message.AR.architektur.altT3')" :text="$t('message.AR.architektur.t3')" :textBottom="false"/>
        <!--<benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'visualisieren.svg'" :text="$t('message.AR.architektur.b4')"/>
        <benefit-card class="col-span-1 row-span-1" :is-text-card="true" :icon="'vergleichen_person.svg'" :text="$t('message.AR.architektur.b5')"/>-->
      </div>
    </div>
  </div>
  <!-- Fazit -->
  <div class="md:grid md:grid-cols-8 md:pl-0 md:pr-0">
    <div class="md:col-start-3 md:col-end-8 mt-32 mb-32 pl-10 pr-10 md:pl-0 md:pr-0">
      <h2 class="text-black font-sen font-bold text-5xl mb-12" v-html="$t('message.AR.conclusion')"></h2>
      <p class="text-black font-source font-semibold text-2xl" v-html="$t('message.AR.architektur.conclusion')"></p>
    </div>
    <get-in-touch/>
  </div>
  <!-- Links zu den anderen Bereichen -->
  <other-customers />
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import BenefitCard from '@/components/BenefitCard.vue'
import GetInTouch from '@/components/GetInTouch.vue'
import OtherCustomers from '@/components/OtherCustomers.vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: 'Architektur',
  components: {
    OtherCustomers,
    SideBreadcrumbs,
    BenefitCard,
    GetInTouch,
    Head
  } // ,
  // setup () {
  //   const scrollVid = ref(null)
  //   return { scrollVid }
  // },
  // created () {
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  // unmounted () {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  // methods: {
  //   handleScroll (event: any): any {
  //     const currentTime = (window.scrollY * 5) / 300
  //     if (this.scrollVid) {
  //       (this.scrollVid as unknown as HTMLMediaElement).currentTime = currentTime
  //     }
  //   }
  // }
})
</script>

<style scoped>

</style>
