<template>
  <Head>
    <title>{{$t('message.seo.try.title')}}</title>
    <meta name="description" :content="$t('message.seo.try.description')"/>
    <meta name="keywords" :content="$t('message.seo.try.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="col-start-2 col-end-8">
        <iframe height=500px width=100% src="https://3d.cityscaper.de/AR/Main-Yard" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'

export default defineComponent({
  name: 'Try',
  components: {
    Head,
    SideBreadcrumbs
  }
})
</script>

<style scoped>

</style>
