<template>
  <div class="md:flex flex-wrap justify-center content-center h-full max-h-screen">
    <div class="flex font-source text-sm uppercase whitespace-nowrap word-spacing-wide transform -rotate-90 tracking-widest">
      <div class="flex-row flex" v-for="(crumb, i) in breadcrumbs" :key="i">
        <router-link :to="crumb.path" :class="{ 'font-bold': i + 1 === breadcrumbs.length }" class="pt-2">{{ crumb.name }}</router-link>
        <div v-if="i + 1 !== breadcrumbs.length" class="w-1 h-1 rounded-full bg-black m-2 pt-1 mt-4"></div>
        <router-link :to="backLink.path" v-if="i + 1 === breadcrumbs.length && breadcrumbs.length > 1" class="pl-24 flex flex-row hover:font-bold cursor-pointer">
          <span class="invisible inline-block align-middle pt-2 font-bold">{{ $t('message.back') }}</span>
          <span class="absolute inline-block align-middle pt-2">{{ $t('message.back') }}</span><div class="inline-block w-8 h-8 arrow-icon bg-black transform -rotate-90"></div></router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SideBreadcrumbs',
  computed: {
    breadcrumbs () {
      const path = this.$route.path.split('/')
      path.shift()
      let cpath = ''
      const ret = []
      for (const pid in path) {
        cpath = cpath + '/' + path[pid]
        ret.push(this.$router.resolve(cpath))
      }
      console.log(ret)
      return ret
    },
    backLink () {
      const path = this.$route.path.split('/')
      const pathstr = this.$route.path
      return this.$router.resolve(pathstr.slice(0, pathstr.length - path[path.length - 1].length - 1))
    }
  }
})
</script>

<style scoped>

</style>
