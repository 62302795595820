
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SideBreadcrumbs',
  computed: {
    breadcrumbs () {
      const path = this.$route.path.split('/')
      path.shift()
      let cpath = ''
      const ret = []
      for (const pid in path) {
        cpath = cpath + '/' + path[pid]
        ret.push(this.$router.resolve(cpath))
      }
      console.log(ret)
      return ret
    },
    backLink () {
      const path = this.$route.path.split('/')
      const pathstr = this.$route.path
      return this.$router.resolve(pathstr.slice(0, pathstr.length - path[path.length - 1].length - 1))
    }
  }
})
