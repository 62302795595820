
import { defineComponent } from 'vue'
import { customers } from '../Customers'

export default defineComponent({
  name: 'OtherCustomers',
  computed: {
    customerList () {
      const ret: { [key: string]: any } = {}
      for (const key in customers) {
        if (customers[key].path !== this.$route.path) {
          ret[key] = customers[key]
        }
      }
      return ret
    }
  }
})
