
import { ref, defineComponent } from 'vue'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import BenefitCard from '@/components/BenefitCard.vue'
import GetInTouch from '@/components/GetInTouch.vue'
import OtherCustomers from '@/components/OtherCustomers.vue'
import { Head } from '@egoist/vue-head'

export default defineComponent({
  name: 'Architektur',
  components: {
    OtherCustomers,
    SideBreadcrumbs,
    BenefitCard,
    GetInTouch,
    Head
  } // ,
  // setup () {
  //   const scrollVid = ref(null)
  //   return { scrollVid }
  // },
  // created () {
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  // unmounted () {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  // methods: {
  //   handleScroll (event: any): any {
  //     const currentTime = (window.scrollY * 5) / 300
  //     if (this.scrollVid) {
  //       (this.scrollVid as unknown as HTMLMediaElement).currentTime = currentTime
  //     }
  //   }
  // }
})
