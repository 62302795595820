<template>
  <Head>
    <title>{{$t('message.seo.kontakt.title')}}</title>
    <meta name="description" :content="$t('message.seo.kontakt.description')"/>
    <meta name="keywords" :content="$t('message.seo.kontakt.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="md:col-start-2 md:col-end-8 mt-16 p-8 md:grid grid-cols-3 gap-12 lg:gap-24 grid-flow-row-dense">
      <div class="col-start-2 col-end-4 flex flex-col">
        <h1 class="flex-grow-0 w-full text-black font-sen font-bold text-4xl mb-8" v-html="$t('message.Kontakt.title')"></h1>
        <p class="flex-grow-0  w-full font-source mb-8" v-html="$t('message.Kontakt.text')"></p>
        <div class="hidden md:block flex-grow"></div>
        <div class="hidden md:block flex-grow-0 w-full relative">
          <div class="absolute hidden lg:block right-0 bottom-0 w-48 xl:w-64 ml-auto mr-10 mt-auto shadow">
            <img class="w-full relative" src="../assets/img/visitenkarte_schwarz-1.svg" alt="businesscard">
          </div>
          <a href="yannic_schwarz.vcard">
            <div class="w-48 xl:w-64 ml-auto lg:mr-48 mb-6 relative mt-auto flip-card cursor-pointer transform -rotate-12"
                 v-on:mouseenter="() => { vcardFlipped = true }"
                @click="downloadVCard">
              <img class="w-full absolute flip-front shadow-xl" :class="{ 'add-after-initial-flip-front' : vcardFlipped }" src="../assets/img/visitenkarte_schwarz-0.svg" alt="businesscard">
              <div class="w-full absolute flip-back shadow-xl" :class="{ 'add-after-initial-flip-back' : vcardFlipped }">
                <img class="w-full" src="../assets/img/visitenkarte_schwarz-1.svg" alt="businesscard">
              </div>
              <img class="w-full relative opacity-0" src="../assets/img/visitenkarte_schwarz-0.svg" alt="businesscard">
            </div>
            <div class="flex flex-row items-center font-source"><p>vCard download</p><div class="arrow-icon-scribble w-8 h-8 bg-black mb-3 ml-4"></div></div>
          </a>
        </div>
      </div>
      <div class="col-start-1 col-end-2">
        <img class="mb-8 md:mb-32" src="../assets/img/kontakt_lb.jpg" :alt="$t('message.Kontakt.altImg')" />
        <div class="block md:hidden">
        <div class="float float-right flex-row items-center font-source"><p>vCard download</p><div class="arrow-icon-scribble w-8 h-8 bg-black ml-4 transform rotate-180"></div></div>
        <a href="yannic_schwarz.vcard">
          <div class="float clear-right float-right md:hidden w-48 shadow-xl transform rotate-12">
            <img class="w-full relative" src="../assets/img/visitenkarte_schwarz-0.svg" alt="businesscard">
          </div>
        </a>
        </div>
        <p class="font-source mb-8" v-html="$t('message.Kontakt.l1')"></p>
        <p class="font-sen font-bold mb-8">Yannic Schwarz</p>
        <a :href="mailto" class="font-source underline hover:text-gray-500" v-html="email"></a>
        <p class="font-source mt-2" v-html="telephone"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Head } from '@egoist/vue-head'
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'

export default defineComponent({
  name: 'Kontakt',
  components: {
    Head,
    SideBreadcrumbs
  },
  data () {
    return {
      email: 'schwarz@cityscaper.de',
      mailto: 'mailto:schwarz@cityscaper.de',
      telephone: '+49 157 3062 2206',
      vcardFlipped: false
    }
  }
})
</script>

<style scoped>

</style>
