<template>
  <div class="md:ml-16 col-start-1 md:col-start-2 col-end-8 bg-black md:grid md:grid-cols-2 mb-32">
    <div class="col-span-1 pt-16 pb-16 pl-24 pr-24 md:pr-0 mt-16 mb-16 flex flex-wrap content-center">
      <div>
        <h2 class="text-white text-3xl font-sen font-bold" v-html="$t('message.AR.getInTouch.h1')"></h2>
        <p class="md:hidden text-white font-source text-lg mt-8" v-html="$t('message.AR.getInTouch.p1')"></p>
        <router-link to="/Kontakt"><button class="rounded-full font-sen mt-8 ml-auto mr-auto bg-white text-black pt-3 pb-3 pr-6 pl-6
        transition duration-40 ease-in-out
          transform hover:-translate-y-1">{{$t('message.home.consulting.btn')}}</button></router-link>
      </div>
    </div>
    <div class="hidden md:block col-span-1 pt-16 pb-16 pl-16 mt-16 mb-16 flex flex-wrap content-center">
      <p class="text-white font-source text-lg" v-html="$t('message.AR.getInTouch.p1')"></p>
    </div>
  </div>
  <div class="col-start-8 col-end-9 bg-black mb-32"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GetInTouch'
})
</script>

<style scoped>

</style>
