<template>
  <Head>
    <title>{{$t('message.seo.leistungen.title')}}</title>
    <meta name="description" :content="$t('message.seo.leistungen.description')"/>
    <meta name="keywords" :content="$t('message.seo.leistungen.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 lg:pl-0 lg:pr-0">
    <div class="hidden md:flex col-span-1">
      <side-breadcrumbs class="w-full"/>
    </div>
    <div class="md:col-start-2 lg:col-start-3 md:col-end-8 mt-16">
      <div class="block">

        <h1 class="text-red font-sen font-bold text-5xl mb-12 mr-8 max-w-prose">{{$t('message.Leistungen.title')}}</h1>
        <!--<h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.Leistungen.subtitle')}}</h2>-->
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.Leistungen.text')}}</p>
        <!--<img class="block md:hidden w-full mt-16 p-16" src="../assets/img/house_complete.svg">-->
      </div>
    </div>
    <div class="hidden md:block col-start-2 col-end-3 pr-2 relative pr-6 relative">
      <div class="text-red font-sen text-xl font-bold md:text-right" v-html="$t('message.Leistungen.dict.example') + '<br>' + $t('message.Leistungen.dict.scenario')"></div>
      <div class="text-red font-sen red-text-outline font-bold text-5xl md:text-right opacity-50 mt-4" v-html="'0' + (selectedScenario + 1)"></div>
    </div>
    <div class="hidden md:block col-start-3 col-end-7 mb-8">
      <h4 class="font-source uppercase text-lg mb-2" v-html="$t('message.Leistungen.s' + (selectedScenario + 1) + '.tagline')"></h4>
      <h3 class="font-bold font-sen text-xl mb-16" v-html="$t('message.Leistungen.s' + (selectedScenario + 1) + '.fullTitle')"></h3>
      <p class="font-source text-lg" v-html="$t('message.Leistungen.s' + (selectedScenario + 1) + '.description')"></p>
    </div>
  </div>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 lg:pl-0 lg:pr-0 relative">
    <!-- scenario selection -->
    <div class="hidden md:block col-start-2 lg:col-start-3 md:col-end-8 lg:col-end-7 pb-6">
      <div class="grid grid-cols-3 gap-1 lg:gap-4">
        <div v-for="i in [0,1,2]" :class="{ 'bg-black text-white': i == selectedScenario, 'bg-white': i != selectedScenario }"
             class="col-span-1 border-black border-2 m-1 p-4 text-center font-source uppercase text-xs lg:text-sm cursor-pointer" :key="i"
              v-on:click="() => {resetScenario(i); selectedScenario = i}">
          <div v-html="$t('message.Leistungen.dict.scenario') + '<br>' + $t('message.Leistungen.s'+(i+1)+'.title')"></div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block absolute left-10 top-0 col-start-1 col-end-3 p-8">
      <div class="relative">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('app'),
                            'slide-out-top': !selectedScenarioList.includes('app') }"
             class="w-full" src="../assets/img/lhouse/8_gro.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('docs'),
                            'slide-out-top': !selectedScenarioList.includes('docs') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/7_tre.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('story'),
                            'slide-out-top': !selectedScenarioList.includes('story') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/6_poo.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('vr'),
                            'slide-out-top': !selectedScenarioList.includes('vr') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/5_tur.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('creator'),
                            'slide-out-top': !selectedScenarioList.includes('creator') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/4_lil.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('web'),
                            'slide-out-top': !selectedScenarioList.includes('web') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/3_gel.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('creator'),
                            'slide-out-top': !selectedScenarioList.includes('creator') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/2_lil.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('variants'),
                            'slide-out-top': !selectedScenarioList.includes('variants') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/1_red.svg">
        <img alt="interaktive grafik" :class="{  'bounce-in-top':  selectedScenarioList.includes('vr'),
                            'slide-out-top': !selectedScenarioList.includes('vr') }"
             class="absolute top-0 left-0 w-full" src="../assets/img/lhouse/0_tur.svg">
      </div>
    </div>
    <!-- scenario number block left -->
    <!--
    <div class="hidden lg:relative col-start-2 col-end-3 pr-6 lg:block">
      <div class="bg-black ml-auto mb-auto w-1/2 2xl:w-1/3 square">
        <div class="text-white absolute top-0 left-0 w-full h-full font-sen font-bold text-2xl 2xl:text-xl flex items-center">
          <div class="m-auto" v-html="'0' + (selectedScenario + 1)"></div>
        </div>
      </div>
    </div>-->
    <div class="hidden md:block col-start-2 lg:col-start-3 md:col-end-8 lg:col-end-7 mb-16">
      <div class="grid grid-cols-3 gap-2 lg:gap-4">
        <!-- Leistungsboxen: -->
        <div v-for="(leistung, key) in leistungen" :key="key" class="">
          <div class="relative cursor-pointer" v-on:click="toggleToScenario(key)">
            <!-- placeholder to generate shape -->
            <div class="square"></div>
            <!-- content -->
            <div class="absolute w-full h-full top-0 left-0 border-dashed border-black border-2 group"
            :class="{ 'border-black' : selectedScenarioList.includes(key),
                      'border-white' : !selectedScenarioList.includes(key) }">
              <!-- Front -->
              <div class="absolute w-full h-full z-0" :class="{'opacity-100' : selectedScenarioList.includes(key),
                                                                'opacity-70' : !selectedScenarioList.includes(key)}">
                <!-- bg image-->
                <div class="absolute w-full h-full top-0 left-0 p-0.5 z-0">
                  <img v-if="!leistung.graphic.endsWith('.mp4')" class="w-full h-full object-cover" :src="require('@/assets/img/leistungen/' + leistung.graphic)" :alt="$t(leistung.textroot + '.title')"/>
                  <video v-if="leistung.graphic.endsWith('.mp4')" autoplay loop muted class="w-full h-full object-cover" :alt="$t(leistung.textroot + '.title')">
                    <source :src="require('@/assets/img/leistungen/' + leistung.graphic)" type="video/mp4">
                  </video>
                </div>
                <!-- bg darkener for textreadability -->
                <div class="absolute w-full h-full top-0 left-0 p-0.5 z-0">
                  <div class="w-full h-full p-2 bg-black opacity-40"></div>
                </div>
                <!-- content text etc -->
                <div class="relative p-3 lg:p-6 w-full h-full z-0">
                  <div class="text-white text:sm lg:text-lg font-sen font-bold" v-html="$t(leistung.textroot + '.title')"></div>
                  <div class="text-white text-xs font-source uppercase" v-html="$t(leistung.textroot + '.subtitle')"></div>
                </div>
              </div>
              <!-- back -->
              <div class="relative min-h-full shadow-2xl z-30 transition ease-in-out opacity-0 group-hover:opacity-100 group-hover:z-30">
                <!-- bg-->
                <div class="absolute min-w-full h-full top-0 left-0 p-0.5 z-30 group-hover:z-30 bg-white"><div class="border-black border-2 h-full min-w-full"></div></div>
                <!-- content text etc -->
                <div class="relative pl-5 lg:pl-6 pr-2 lg:pr-6 pt-1 lg:pt-2 pb-1 lg:pb-2 min-h-full z-30 group-hover:z-40">
                  <!--<div class="text-black text-xl font-sen font-bold" v-html="$t(leistung.textroot + '.title')"></div>-->
                  <div v-for="(bullet, key)  in bullets[key]" :key="key" class="text-black text-xs font-source z-50">
                    <ul class="list-disc md:pl-1 lg:pl-4">
                      <li v-html="bullet" class="mt-2 mb-2"></li>
                    </ul>
                    <!--<div v-for="(item, index) in bullet" :key="index" class="" v-html="item"></div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile version: -->
    <div class="md:hidden mb-32">
      <div v-for="(leistung, key) in leistungen" :key="key" class="w-full flex flex-wrap even:flex-row-reverse even:text-right">
        <div class="flex-1">
          <div class="p-6 h-full flex flex-col">
            <div class="flex-grow-0 text-black text-xl font-sen font-bold mb-2" v-html="$t(leistung.textroot + '.title')"></div>
            <div class="flex-grow text-black text-sm font-source uppercase" v-html="$t(leistung.textroot + '.subtitle')"></div>
            <div class="font-sen flex-grow-0 flex flex-row items-center cursor-pointer"
                 v-on:click="() => {expandedLeistung = expandedLeistung === key? '' : key}">{{$t('message.more2')}}
              <div class="ml-2 w-2 h-2 bg-black triangle-icon"
              :class="{ 'transform rotate-90': expandedLeistung === key }"></div>
            </div>
          </div>
        </div>
        <div class="flex-1 relative">
          <div class="relative">
            <div class="absolute w-full h-full top-0 left-0 p-0.5 z-0">
              <img v-if="!leistung.graphic.endsWith('.mp4')" class="w-full h-full object-cover" :src="require('@/assets/img/leistungen/' + leistung.graphic)" :alt="$t(leistung.textroot + '.title')"/>
              <video v-if="leistung.graphic.endsWith('.mp4')" autoplay loop muted class="w-full h-full object-cover" :alt="$t(leistung.textroot + '.title')">
                <source :src="require('@/assets/img/leistungen/' + leistung.graphic)" type="video/mp4">
              </video>
            </div>
            <div class="square"></div>
          </div>
        </div>
        <!-- insert bullets after -->
        <div class="flex-grow-1 order-last w-full text-left overflow-hidden transform transition-transform ease-in-out duration-500"
             :class="{'max-h-full' : expandedLeistung === key, 'max-h-0' : expandedLeistung !== key}">
          <div class="p-8">
            <div v-for="(bullet, key)  in bullets[key]" :key="key" class="text-black text-md font-source z-50">
              <ul class="list-disc pl-4">
                <li v-html="bullet" class="mt-2 mb-2"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Mobile Version -->

    <!-- Wir begleiten Sie... -- gestrichen -->
    <!--<div class="col-start-3 col-end-7 mb-32">
      <h2 v-html="$t('message.Leistungen.process.title')"></h2>
      <div class="grid grid-cols-4">
        <div class="col-span-1">01</div>
        <div class="col-span-3">
          <h5 v-html="$t('message.Leistungen.process.t1')"></h5>
          <p v-html="$t('message.Leistungen.process.p1')"></p>
        </div>
        <div class="col-span-1">02</div>
        <div class="col-span-3">
          <h5 v-html="$t('message.Leistungen.process.t2')"></h5>
          <p v-html="$t('message.Leistungen.process.p2')"></p>
        </div>
        <div class="col-span-1">03</div>
        <div class="col-span-3">
          <h5 v-html="$t('message.Leistungen.process.t3')"></h5>
          <p v-html="$t('message.Leistungen.process.p3')"></p>
        </div>
      </div>
    </div>-->
  </div>
  <!-- Fazit -->
  <div class="md:grid md:grid-cols-8 md:pl-0 md:pr-0">
    <get-in-touch/>
  </div>
</template>

<script type="ts">
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
import GetInTouch from '@/components/GetInTouch.vue'
import { leistungen, scenario } from '@/Leistungen'
import { messages } from '@/locales/locale'
import { Head } from '@egoist/vue-head'

export default {
  name: 'Leistungen',
  components: {
    SideBreadcrumbs,
    GetInTouch,
    Head
  },
  data () {
    return {
      leistungen,
      scenario,
      selectedScenario: 0,
      selectedScenarioList: Array.from(scenario[0]),
      expandedLeistung: ''
    }
  },
  computed: {
    bullets () {
      const bulletDict = {}
      console.log(Object.keys(leistungen))
      for (const key in leistungen) {
        let bullet = this.$t(leistungen[key].textroot + '.bullets.' + 0)
        let bi = 0
        bulletDict[key] = []
        while (bullet && !bullet.startsWith(leistungen[key].textroot) && bi < 40) {
          bulletDict[key].push(bullet)
          bi = bi + 1
          bullet = this.$t(leistungen[key].textroot + '.bullets.' + bi)
        }
      }
      return bulletDict
    }
  },
  methods: {
    resetScenario (i) {
      this.selectedScenarioList = Array.from(scenario[i])
    },
    toggleToScenario (key) {
      const idx = this.selectedScenarioList.findIndex((el) => key === el)
      if (idx >= 0) {
        this.selectedScenarioList.splice(idx, 1)
      } else {
        this.selectedScenarioList.push(key)
      }
    }
  }
}
</script>

<style scoped>

</style>
