// &shy; is a hint for the site renderer where to break the word
export const leistungen: { [key: string]: any } = {
  app: {
    graphic: 'moneyshot.jpg',
    textroot: 'message.Leistungen.app'
  },
  vr: {
    graphic: 'vr_viewer.jpg',
    textroot: 'message.Leistungen.vr'
  },
  web: {
    graphic: 'microsite.jpg',
    textroot: 'message.Leistungen.web'
  },
  creator: {
    graphic: 'move_bench.mp4',
    textroot: 'message.Leistungen.creator'
  },
  umfrage: {
    graphic: 'poll.jpg',
    textroot: 'message.Leistungen.umfrage'
  },
  variants: {
    graphic: 'VaraintShiftB1cropped.mp4',
    textroot: 'message.Leistungen.variants'
  },
  pool: {
    graphic: 'mpool.jpg',
    textroot: 'message.Leistungen.pool'
  },
  story: {
    graphic: 'luetticherThumb.jpg',
    textroot: 'message.Leistungen.story'
  },
  docs: {
    graphic: 'analyse.jpg',
    textroot: 'message.Leistungen.docs'
  }
}

export const scenario = [
  ['app', 'creator', 'umfrage', 'web', 'docs'],
  ['app', 'web', 'story', 'pool', 'variants', 'docs'],
  ['app', 'web', 'variants', 'creator', 'vr', 'pool']
]
