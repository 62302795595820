<template>
  <div class="grid grid-cols-8 bg-gray-200 mt-32 pb-32 pt-32">
    <div class="col-start-2 col-end-8">
      <h3 class="text-black font-sen font-bold text-xl mb-12" v-html="$t('message.AR.others')"></h3>
      <div class="flex flex-col space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
        <router-link class="bg-white p-8 lg:col-span-1 flex flex-row hover:shadow-2xl transform hover:-translate-y-1 cursor-pointer" v-for="(customer, key) in customerList" :key="key" :to="customer.path">
          <img class="w-16 h-16 mr-2" :src="require('../assets/ui/' + customer.icon)" :alt="'Icon ' + customer.name"/>
          <p class="smart-word-break m-auto text-black text-lg lg:text-sm font-sen font-bold" v-html="customer.name"></p>
          <div class="flex lg:hidden mt-auto mb-auto xl:flex h-12 w-12 arrow-icon bg-red"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { customers } from '../Customers'

export default defineComponent({
  name: 'OtherCustomers',
  computed: {
    customerList () {
      const ret: { [key: string]: any } = {}
      for (const key in customers) {
        if (customers[key].path !== this.$route.path) {
          ret[key] = customers[key]
        }
      }
      return ret
    }
  }
})
</script>

<style scoped>

</style>
