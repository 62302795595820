<template>
  <Head>
    <title>{{$t('message.seo.presse.title')}}</title>
    <meta name="description" :content="$t('message.seo.presse.description')"/>
    <meta name="keywords" :content="$t('message.seo.presse.keywords')"/>
  </Head>
  <div class="md:grid md:grid-cols-8 pl-10 pr-10 md:pl-0 md:pr-0">
    <side-breadcrumbs class="hidden md:flex col-span-1"/>
    <div class="md:col-start-3 md:col-end-9 mt-16">
      <div class="block">
        <div class="hidden md:block float-right md:col-start-6 md:col-end-9 w-5/12 ml-4 lg:ml-16 xl:ml-20 mb-16">
          <img class="w-full mt-16" src="../assets/img/PresseFoto.jpg" :alt="$t('message.presse.imgAlt')">
        </div>
        <h1 class="text-red font-sen font-bold text-5xl mb-12 mr-8 max-w-prose">{{$t('message.presse.title')}}</h1>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.presse.subtitle')}}</h2>
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.presse.text')}}</p>
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.presse.text2')}}</p>
        <p class="font-source text-lg text-black mb-10 mr-8 max-w-prose">{{$t('message.presse.text3')}}</p>
        <h2 class="text-black font-sen font-bold text-2xl mb-6 mr-8 max-w-prose">{{$t('message.presse.h2')}}</h2>
        <p class="font-sen font-bold mb-8">Yannic Schwarz</p>
        <a :href="mailto" class="font-source underline hover:text-gray-500" v-html="email"></a>
        <p class="font-source mt-2" v-html="telephone"></p>
        <a href="https://www.linkedin.com/company/cityscaper" class="font-source underline hover:text-gray-500" target="_blank" rel="noopener noreferrer">LinkedIn<br></a>
        <a href="https://www.instagram.com/cityscaper.de/" class="font-source underline hover:text-gray-500" target="_blank" rel="noopener noreferrer">Instagram<br></a>
        <a href="https://www.facebook.com/cityscaper.de" class="font-source underline hover:text-gray-500" target="_blank" rel="noopener noreferrer">Facebook<br></a>
        <!-- <p class="font-source text-lg text-black max-w-prose mb-20">{{$t('message.presse.p2')}}</p> -->
        <img class="block md:hidden w-full mt-16 p-8" src="../assets/img/PresseFoto.jpg" :alt="$t('message.presse.imgAlt')">
      </div>
    </div>
  </div>
  <!-- Section Customer Segments -->
  <div class="mt-32 grid grid-cols-8 bg-gray-200">
    <PressCarousell />
  </div>
  <!-- <div class="col-start-3 col-end-8 xl:col-end-7">
    <div class="col-start-2 col-end-8">
      <h3 class="font-sen font-bold text-3xl mb-8" v-html="$t('message.presse.subtitle2')"></h3>
      <p class="max-w-prosefont-source mb-16" v-html="$t('message.presse.pressArticles')"></p>
    </div>
  </div> -->
  <!-- Fazit -->
  <div class="md:grid md:grid-cols-8 md:pl-0 md:pr-0">
    <div class="md:col-start-3 md:col-end-8 mt-32 mb-32 pl-10 pr-10 md:pl-0 md:pr-0">
      <h2 class="text-black font-sen font-bold text-4xl mb-12" v-html="$t('message.presse.subtitle2')"></h2>
      <p class="text-black font-source text-1xl" v-html="$t('message.presse.pressArticles')"></p>
    </div>
    <div class="md:col-start-3 md:col-end-8 mt-32 mb-32 pl-10 pr-10 md:pl-0 md:pr-0">
      <h2 class="text-black font-sen font-bold text-4xl mb-12" v-html="$t('message.presse.subtitle3')"></h2>
      <p class="text-black font-source text-1xl" v-html="$t('message.presse.info')"></p>
    </div>
    <div class="md:col-start-3 md:col-end-8 mt-32 mb-32 pl-10 pr-10 md:pl-0 md:pr-0">
      <h2 class="text-black font-sen font-bold text-4xl mb-12" v-html="$t('message.presse.subtitle4')"></h2>
      <p class="text-black font-source text-1xl" v-html="$t('message.presse.downloads')"></p>
      <p class="text-black font-source text-1xl"><span v-html="$t('message.presse.disclaimer')"></span><a :href="mailtoInfo" class="font-source underline hover:text-gray-500" v-html="emailInfo"></a>.</p>
    </div>
    <!-- <get-in-touch/> -->
  </div>
</template>

<script lang="ts">
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
// import CustomerSegmentCard from '@/components/CustomerSegmentCard.vue'
import { Head } from '@egoist/vue-head'
// import { customers } from '../Customers'
import { defineComponent } from 'vue'
import PressCarousell from '@/components/PressCarousell.vue'
// import GetInTouch from '@/components/GetInTouch.vue'

export default defineComponent({
  name: 'Kontakt',
  components: {
    Head,
    SideBreadcrumbs,
    PressCarousell
    // GetInTouch
    // CustomerSegmentCard
  },
  data () {
    return {
      email: 'schwarz@cityscaper.de',
      mailto: 'mailto:schwarz@cityscaper.de',
      telephone: '+49 157 3062 2206',
      emailInfo: 'info@cityscaper.de',
      mailtoInfo: 'mailto:info@cityscaper.de'
      // customers: customers,
      // vcardFlipped: false
    }
  }
})
</script>
