
import SideBreadcrumbs from '@/components/SideBreadcrumbs.vue'
// import CustomerSegmentCard from '@/components/CustomerSegmentCard.vue'
import { Head } from '@egoist/vue-head'
// import { customers } from '../Customers'
import { defineComponent } from 'vue'
import PressCarousell from '@/components/PressCarousell.vue'
// import GetInTouch from '@/components/GetInTouch.vue'

export default defineComponent({
  name: 'Kontakt',
  components: {
    Head,
    SideBreadcrumbs,
    PressCarousell
    // GetInTouch
    // CustomerSegmentCard
  },
  data () {
    return {
      email: 'schwarz@cityscaper.de',
      mailto: 'mailto:schwarz@cityscaper.de',
      telephone: '+49 157 3062 2206',
      emailInfo: 'info@cityscaper.de',
      mailtoInfo: 'mailto:info@cityscaper.de'
      // customers: customers,
      // vcardFlipped: false
    }
  }
})
