export const team = {
  Yannic: {
    fullName: 'Yannic Schwarz',
    portrait: 'yannic.jpg'
  },
  Robin: {
    fullName: 'Robin Römer',
    portrait: 'robin.jpg'
  },
  Sebastian: {
    fullName: 'Sebastian Witt',
    portrait: 'sebastian.jpg'
  },
  Felix: {
    fullName: 'Felix Horn',
    portrait: 'felix.jpg'
  },
  Bernward: {
    fullName: 'Bernward Hoffmann',
    portrait: 'bernward.jpg'
  },
  Rukas: {
    fullName: 'Rukas Skirkevicius',
    portrait: 'rukas.jpg'
  },
  Morne: {
    fullName: 'Morne Gouws',
    portrait: 'morne.jpg'
  }
}
